/*!
jquery.necessary.js
*/
$(function () {
	/*
	 * 必須項目の未記入数表示
	 *
	 *・class名necessaryに内包されるフォーム部品（input,select,textarea）を対象
	 *・一つのnecessary内に複数のフォーム部品がある場合、一つでも未記入がある場合
	 * 　には未記入としてカウントアップする
	 *・input:radioの扱いは同一nameでchekedな状態のものが存在するか検証
	 *・input:checkboxの扱いは同一nameで一つ以上のチェックされたものがあるかを検証
	 *・内容が空のものを未記入と判断。0は記入されたと判断する。これはselectも同じ。
	 *
	 *・javascriptによってvalueをセットした時、onChangeイベントは発生しないので、
	 * 　自前でnecessaryCheck()をコールする必要がある。
	 */
	// 指定エレメントに内包されるフォーム部品を必須とみなす
	var _necessaryEls = $('.nes');
	// 残り必須項目数をアウトプットするエレメント
	var _countEl = $('.count');
	// 前回のカウント数を記憶しておく
	var lastCount = null;
	// カウントが変化した時に呼び出す関数
	var onCountUpdated = function (cnt, last) {
		// 0になった
		if (last != 0 && cnt == 0) {
			$('.form-submit').slideDown();
			$('.form-error').hide();
		}
		// 0じゃなくなった
		else if (!last && cnt != 0) {
			$('.form-submit').hide();
			$('.form-error').slideDown();
		}
	};
	/**
	 * necessaryCheck
	 *
	 * _necessaryElsで指定のエレメントを全て検査し、
	 * _countElにアウトプットする関数
	 *
	 * @param void
	 * @return void
	 */
	 function necessaryCheck() {
	 	var cnt = 0;
	 	_necessaryEls.each(function () {
	 		var parent = this;
			var flag = false; // チェックに引っかかった時True
			var skip = []; // radioなど同一nameを調べた際にスキップするよう変数
			$('select, input, textarea', this).each(function () {
				if (flag) return;
				var _this = $(this);
				var name = _this.attr('name');
				if ($.inArray(name, skip) >= 0) return; // 検査済みスキップ
				// 基本フォーム
				if (_this.is('select') || _this.is('textarea') || _this.is('input:text') || _this.is('input[type=email]') || _this.is('input[type=tel]')) {
					if (_this.val() == '') {
						flag = true;
					}
					return;
				}
				// ラジオまたはチェックボックス
				else if (_this.is('input:radio, input:checkbox')) {
					// 同一nameでのchekedを調べる
					var _checked = $('[name="' + name + '"]:checked', parent);
					// checkedがない＝必須チェックに引っかかった
					if (!_checked.length) {
						flag = true;
						return;
					}
					// 必須チェックをスルーした
					else {
						// skip に記憶
						skip.push(name);
					}
				}
			});
			if (flag) cnt++;
		});
	 	if (lastCount == null || lastCount != cnt) {
	 		_countEl.text(cnt);
			// コールバック
			if ($.isFunction(onCountUpdated)) {
				onCountUpdated(cnt, lastCount);
			}
			lastCount = cnt;
		}
	}
	// 必須項目のchengeイベントにnecessaryCheckをバインド
	$('select, input, textarea', _necessaryEls).change(necessaryCheck);
	// 初回一度強制的にnecessaryCheck発動
	necessaryCheck();
	// window.でnecessaryCheckを使えるようにする
	window.necessaryCheck = necessaryCheck;
	/* 必須項目の未記入数表示関連ここまで */
});

//入力実行中にイベントを監視
$(function () {
	$('input,textarea').bind('keydown keyup keypress', function () {
		necessaryCheck();
	});
});