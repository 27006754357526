// トップページ-メーカー・車種からさがす
jQuery(function($){
	var searchUrlDefault = 'https://' + location.hostname + '/case/';
	$('#searchMakerHref').attr('href', searchUrlDefault);

	$('.js-search-maker-select').change(function() {
		var getMaker = $('.js-search-maker-select option:selected').data('url');
		$('#searchMakerUrl').text(getMaker);
	});

	$('.js-search-brand-select').change(function() {
		var getBrand = $('.js-search-brand-select option:selected').data('url');
		$('#searchBrandUrl').text(getBrand);
	});

	$('.js-search-maker-select,.js-search-brand-select').change(function() {
		var selectMakerUrl = $('#searchMakerUrl').text();
		var searchBrandUrl = $('#searchBrandUrl').text();
		if (selectMakerUrl == 'all' &&　searchBrandUrl == 'all') {
			var searchUrl = 'https://' + location.hostname + '/case/';
			$('#searchMakerHref').attr('href', searchUrl);
		}else if ( selectMakerUrl != 'all' && searchBrandUrl == 'all') {
			var searchUrl = 'https://' + location.hostname + '/maker/' + selectMakerUrl;
			$('#searchMakerHref').attr('href', searchUrl);
		}else if ( searchBrandUrl && searchBrandUrl != 'all') {
			var searchUrl = 'https://' + location.hostname + '/maker/' + searchBrandUrl;
			$('#searchMakerHref').attr('href', searchUrl);
		}else if (selectMakerUrl) {
			var searchUrl = 'https://' + location.hostname + '/maker/' + selectMakerUrl;
			$('#searchMakerHref').attr('href', searchUrl);
		}else{
			var searchUrl = 'https://' + location.hostname + '/case/';
			$('#searchMakerHref').attr('href', searchUrl);
		}
	});
});

// トップページ-パーツ・車種からさがす
jQuery(function($){
	var searchUrlDefault = 'https://' + location.hostname + '/option/';
	$('#searchOptionHref').attr('href', searchUrlDefault);

	$('.js-search-option-select').change(function() {
		var getOption = $('.js-search-option-select option:selected').data('url');
		$('#searchOptionUrl').text(getOption);
	});

	$('.js-search-option-select').change(function() {
		var selectOptionrUrl = $('#searchOptionUrl').text();
		if (selectOptionrUrl != 'all') {
			var searchUrl = 'https://' + location.hostname + '/parts/' + selectOptionrUrl;
			$('#searchOptionHref').attr('href', searchUrl);
		}else if(selectOptionrUrl == 'all'){
			var searchUrl = 'https://' + location.hostname + '/option/';
			$('#searchOptionHref').attr('href', searchUrl);
		}
	});
});

// トップページ--タブ切り換え
jQuery(function($){
	$('.search-tab').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show');
		const index = $(this).index();
		$('.search-panel').eq(index).addClass('is-show');
	});
});

// 施工事例詳細ページ--タブ切り換え
jQuery(function($){
	$('.option-tab').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show');
		const index = $(this).index();
		$('.option-panel').eq(index).addClass('is-show');
	});
});

// 施工事例詳細ページのトップスライダー
var galleryThumbs = new Swiper('.case-gallery-thumbs', {
	slidesPerView: 10,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});
var galleryTop = new Swiper('.case-gallery-top', {
	loop: true,
	spaceBetween: 8,
	slidesPerView: 1.5,
	centeredSlides : true,
	grabCursor: true,
	// breakpoints: {
	// 	480: {
	// 		slidesPerView: 1,
	// 		centeredSlides : false,
	// 		spaceBetween: 0,
	// 	}
	// },
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: galleryThumbs,
	}
});

// 施工事例詳細ページのトップスライダー
var galleryThumbs = new Swiper('.option-gallery-thumbs', {
	slidesPerView: 10,
	watchSlidesVisibility: true,
	watchSlidesProgress: true,
});
var galleryTop = new Swiper('.option-gallery-top', {
	loop: true,
	spaceBetween: 8,
	slidesPerView: 1.5,
	centeredSlides : true,
	grabCursor: true,
	// breakpoints: {
	// 	640: {
	// 		spaceBetween: 0,
	// 		slidesPerView: 1
	// 	}
	// },
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	thumbs: {
		swiper: galleryThumbs,
	}
});

// 施工事例詳細ページ（施工事例）の取り付け方法のスライダー
var caseMountSlider = new Swiper ('.case-mount-slider', {
	loop: false,
	slidesPerView: 3,
	spaceBetween: 22,
	slidesPerGroup: 3,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
	}
})

// 施工事例詳細ページ（オプション）の取り付け方法のスライダー
var caseMountSlider = new Swiper ('.option-mount-slider', {
	loop: false,
	slidesPerView: 3,
	spaceBetween: 22,
	slidesPerGroup: 3,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
	}
})

// 10の理由のスライダー
var advantageSlider = new Swiper ('.js-advantage-slider', {
	loop: true,
	slidesPerView: 1,
	grabCursor: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
	}
})

// トップページ-メーカー・車種からさがすの絞り込み
$(function() {
	/* 国名が変更されたら発動 */
	$('.js-select-maker').change(function() {
		/* 選択されている国のクラス名を取得 */
		var makerName = $('.js-select-maker option:selected').attr('class');
		/* 都市名の要素数を取得 */
		var count = $('.js-select-brand').children().length;
		/* 都市名の要素数分、for文で回す */
		for (var i=0; i<count; i++) {
			var brandName = $('.js-select-brand option:eq(' + i + ')');
			if(brandName.attr('class') === makerName) {
				/* 選択した国と同じクラス名だった場合 */
				brandName.show();
			}else{
				/* 選択した国とクラス名が違った場合 */
				if(brandName.attr('class') === 'js-static-message') {
					/* 「都市名を選択して下さい」という要素だった場合 */
					brandName.show();  /*「都市名を選択して下さい」を表示させる */
					brandName.prop('selected', true);  /*「都市名を選択して下さい」を強制的に選択されている状態にする */
					/* brandName.attr('disabled', 'disabled'); */
					$('.js-search-brand-select').trigger('change');
				} else {
					/* 「都市名を選択して下さい」という要素でなかった場合 */
					brandName.hide();
					$('.js-search-brand-select').trigger('change');
				}
			}
		}
	})
})

// 対応車種・料金表の絞り込み
$(function(){
	$('#searchNarrowing').bind('click',function(){
		var re = new RegExp($('#searchKyeword').val());
		$('#stockLists .list-i').each(function(){
			var txt = $(this).find('p:eq(0)').html();
			if(txt.match(re) != null){
				$(this).show();
			}else{
				$(this).hide();
				$('.list-i-thead').hide();
			}
		});
		$('#stockLists .list-sp-i').each(function(){
			var txt = $(this).find('p:eq(0)').html();
			if(txt.match(re) != null){
				$(this).show();
			}else{
				$(this).hide();
				$('.list-sp-i-thead').hide();
			}
		});
	});
	$('#searchReset').bind('click',function(){
		$('#searchKyeword').val('');
		$('#stockLists .list-i').show();
		$('.list-i-thead').show();
		$('#stockLists .list-sp-i').show();
		$('.list-sp-i-thead').show();
	});
});

// ヘッダー固定 - htmlに余白設定
$(function(){
	var headerHeight = $('header').outerHeight();
	$('html').css('padding-top', headerHeight);
});

// ヘッダー固定 - htmlに余白設定
$(function() {
	var $win = $(window);
	$header = $('header');
	var animationStartPosition = $('main').offset();
	var animationStartPosition = animationStartPosition.top;
	var animationClass = 'animation';
	$win.on('load scroll', function() {
		var value = $(this).scrollTop();
		if ( value > animationStartPosition ) {
			$header.addClass(animationClass);
		} else {
			$header.removeClass(animationClass);
		}
	});
});


// スムーススクロール
// ignoreでmodaaalを除外
$(function(){
	var scroll = new SmoothScroll('a[href*="#"]', {
		header: '#header',
		ignore: '.js'
	});
});

// modaalでメニュー表示
$(function() {
	$('.js-fsnav').modaal({
		fullscreen: true
	});
});

// 郵便番号から住所を自動入力
jQuery(function( $ ) {
	jQuery( 'input[name="zipcode[data][1]"]' ).keyup( function( e ) {
		AjaxZip3.zip2addr('zipcode[data][0]','zipcode[data][1]','pref','address');
	})
	jQuery( 'input[name="s-zipcode[data][1]"]' ).keyup( function( e ) {
		AjaxZip3.zip2addr('s-zipcode[data][0]','s-zipcode[data][1]','s-pref','s-address');
	})
	if($('#mw_wp_form_mw-wp-form-1352').hasClass('mw_wp_form mw_wp_form_confirm')){
		$('.comments').css('display', 'none');
	}
});

// プルダウン変更時に遷移
$(function () {
	$('.js-pulldown-link').change(function() {
		if ($(this).val() != '') {
			window.location.href = $(this).val();
		}
	});
});


// 見積フォームにてmodaalでメニュー表示
$(function() {
	$('.js-modaal-option').modaal();
});

// トグルメニューを表示（テスト検証用）
// $(function () {
// 	jQuery.event.add(window,"load",function() {
// 		$('.js-fsnav').trigger('click');
// 	});
// });
